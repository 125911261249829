<template>
  <div class="flex-col page">
    <div class="flex-col group_1">
      <div class="flex-col group_2">
        <!-- 头部 -->
        <div class="justify-between section_2">
          <span class="text_1">处理列表</span>
        </div>
        <div class="flex-col section_3">
          <div class="flex-col">
            <span class="text_2">请选择零部件维修方式</span>
            <div class="flex-row list">
              <!-- 单选列表 -->
              <div class="list-item flex-row">
                <van-radio-group v-model="radio" direction="horizontal">
                  <van-radio :name="index" :key="index" v-for="(item, index) in select">{{item.name}}</van-radio>
                </van-radio-group>
              </div>
            </div>
          </div>
          <!-- 表单列表 -->
          <span class="text_6">填写零部件信息</span>
          <div class="flex-col list_1">
            <div class="list-item_1 justify-between" :key="i" v-for="(item, i) in list">
              <span class="text_7">{{item.title}}</span>
              <span class="text_9">
                <input type="text" v-model="item.text" dir="rtl" placeholder="请填写" />
              </span>
            </div>
          </div>
          <!-- 上传 -->
          <span class="text_23">上传零部件图片</span>
          <div class="flex-col items-center image-wrapper">
            <van-uploader v-model="fileList" :after-read="afterRead" multiple :max-count="1" />
          </div>
        </div>
      </div>
      <div class="flex-row group_14">
        <div class="flex-col items-center text-wrapper" @click="fok()">
          <span>继续添加</span>
        </div>
        <div class="flex-col items-center text-wrapper_1" @click="oko()">
          <span>完成</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {add} from '@/api/Maintenance/Alarm/all'
import { Toast } from 'vant';
export default {
  data() {
    return {
      list: [
        { title: '部件标识编码', text: '' },
        { title: '产品代理商', text: '' },
        { title: '出厂编号', text: '' },
        { title: '产品名称', text: '' },
        { title: '产品型号', text: '' },
        { title: '产品参数', text: '' },
        { title: '设计寿命', text: '' }
      ],
      select: [{ name: '维修' }, { name: '更换' }],
      radio: '',
      fileList: []
    }
  },
  methods: {
    afterRead(file) {
      file.status = 'uploading'
      file.message = '上传中...'

      setTimeout(() => {
        file.status = 'failed'
        file.message = '上传失败'
      }, 1000)
    },
    oko(){
      // console.log(this.radio);
      if(this.list[0].text.length>0&&this.list[1].text.length>0&&this.list[2].text.length>0&&this.list[3].text.length>0&&this.list[4].text.length>0&&this.list[5].text.length>0&&this.list[6].text.length>0&&this.radio!==''){
        // 确认不为空的时候发送添加请求
        add({
          pid:'',
          type:this.radio+1,
          AlarmId:this.$route.params.id,
          IdentificationCode:this.list[0].text,
          Agent:this.list[1].text,
          ManufacturingNumber:this.list[2].text,
          ProductName: this.list[3].text,
          ProductModel:this.list[4].text,
          ProductParameter:this.list[5].text,
          DesignedLife:this.list[6].text,
          Picture: "",
        }).then(res=>{
          console.log(res);
          if(res.data.Result===200){
            Toast('添加成功')
            this.$router.push('/maintenance/alarm/part-list/'+sessionStorage.getItem('uid'))
          }else{
            Toast('添加失败')
          }
        })
      }else{
        Toast('有必填项没有填写哦')
      }
    },
    fok(){
      //利用刷新跳转来进行继续添加
      history.go(0),
      this.$router.push('/maintenance/alarm/part-add/'+this.$route.params.id)
    }
  }
}
</script>
<style scoped>
@import '../../../assets/common.css';
.list-item_1 {
  padding-bottom: 1.13rem;
  border-bottom: solid 0.063rem rgb(243, 249, 244);
}
.list-item {
  color: rgb(102, 102, 102);
  font-size: 0.88rem;
  line-height: 0.88rem;
  white-space: nowrap;
}
.text_7 {
  color: rgb(102, 102, 102);
  font-size: 0.88rem;
  line-height: 0.88rem;
  white-space: nowrap;
}
.text_9 {
  color: rgb(153, 153, 153);
  font-size: 0.88rem;
  line-height: 0.88rem;
  white-space: nowrap;
}
.text_9 input {
  border: 0;
  outline: none;
}
.page {
  padding-top: 0.063rem;
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.section_1 {
  padding: 0.94rem 0.81rem 0.88rem;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 0.94rem;
  white-space: nowrap;
  background-color: rgb(237, 237, 237);
}
.group_1 {
  padding-bottom: 0.94rem;
  overflow-y: auto;
}
.image_1 {
  margin-right: 0.25rem;
  align-self: center;
  width: 1.13rem;
  height: 0.25rem;
}
.group_2 {
  height: 46.75rem;
}
.group_14 {
  margin-top: 2.19rem;
  padding: 0 1.25rem;
}
.image {
  margin-bottom: 0.13rem;
  width: 0.81rem;
  height: 0.81rem;
}
.text {
  margin-left: 0.69rem;
}
.section_2 {
  padding-left: 1.5rem;
  padding-bottom: 3.5rem;
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  line-height: 1.44rem;
  white-space: nowrap;
  background-image: url(../../../assets/imgbg1_03.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.section_3 {
  margin-top: -1rem;
  padding: 1.94rem 0.88rem 2.69rem 1.25rem;
  background: #fff;
  border-radius: 1rem;
  position: relative;
}
.text-wrapper {
  padding: 1.13rem 0 1.06rem;
  flex: 1 1 10rem;
  color: rgb(255, 255, 255);
  font-size: 1rem;
  line-height: 0.94rem;
  white-space: nowrap;
  background: rgb(0, 93, 219);
  border-radius: 2rem;
  height: 3.13rem;
}
.text-wrapper_1 {
  margin-left: 0.94rem;
  padding: 1.06rem 0;
  flex: 1 1 10rem;
  color: rgb(255, 255, 255);
  font-size: 1rem;
  line-height: 1rem;
  white-space: nowrap;
  background: rgb(0, 93, 219);
  border-radius: 2rem;
  height: 3.13rem;
}
.text_1 {
  margin-top: 1.88rem;
}
.group_3 {
  width: 6.44rem;
  position: relative;
}
.text_6 {
  margin-top: 1.38rem;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 1rem;
  white-space: nowrap;
}
.list_1 {
  margin-top: 1.31rem;
}
.text_23 {
  margin-top: 1.19rem;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 0.94rem;
  white-space: nowrap;
}
.image-wrapper {
  margin-top: 1.13rem;
  width: 4.81rem;
}
.image_2 {
  width: 5.31rem;
  height: 6.75rem;
}
.image_3 {
  width: 3.25rem;
  height: 1.44rem;
  position: absolute;
  left: 0;
  top: 2.38rem;
}
.text_2 {
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 1rem;
  white-space: nowrap;
}
.list {
  margin-top: 1.19rem;
  padding: 0 0.75rem;
}
</style>

